import React from 'react';
import './App.css';

const bgImage =
  'https://firebasestorage.googleapis.com/v0/b/dragonballsuper-6d883.appspot.com/o/DragonBall.jpg?alt=media&token=ae608de9-05af-46cb-be0a-de8bf8fe3c4e';

function App() {
  return (
    <div
      className="App"
      style={{
        background: `url("${bgImage}") no-repeat center fixed`,
        backgroundSize: 'cover',
        width: '100vw',
        height: '100vh',
      }}
    >
      <label
        style={{
          position: 'absolute',
          top: '24%',
          left: '3%',
          fontSize: '50px',
        }}
      >
        For all people who love Dragon Ball
      </label>
      <button
        style={{
          position: 'absolute',
          height: '80px',
          width: '200px',
          background: '#f67e7d',
          top: '65%',
          left: '17%',
          borderRadius: '10px',
          borderWidth: '10px',
          borderColor: '#ffb997',
          fontSize: '32px',
          fontWeight: 200,
          color: 'gray',
          fontFamily: 'Arial',
          cursor: 'pointer',
          outline: 'none',
        }}
      >
        <a
          href="https://firebasestorage.googleapis.com/v0/b/dragonballsuper-6d883.appspot.com/o/DragonBallSuper.jar?alt=media&token=05d71dd4-b04e-418f-94f9-bb16264ebe44"
          download
          style={{ textDecoration: 'none' }}
        >
          Download
        </a>
      </button>
      <label
        style={{
          position: 'absolute',
          bottom: '1%',
          left: '1%',
          fontSize: '24px',
        }}
      >
        Developed by Owen Zhong & Sherry Zhou
      </label>
    </div>
  );
}

export default App;
